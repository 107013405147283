import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Page_07_2024 from '../views/Page_07_2024.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/Page_07_2024',
    name: 'Page_07_2024',
    component: Page_07_2024
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
